import request from '@/utils/request'

export function getConfigJSON (params) {
  return request({
    url: '/common/api/config/getJson',
    method: 'post',
    data: {
      data: params
    }
  })
}
