<template>
  <div id="container">
    <div id="left">
      <div id="leftHeader">
        <img class="icon" src="../images/ic_select_collection.png"/>
        {{getLeftHeader()}}
      </div>
      <div id="leftInput">
        <el-input v-model="newPlanTitle"  placeholder="添加任务 回车即可保存" @keyup.enter.native="dbAddPlan()"></el-input>
      </div>
      <el-scrollbar id="list" class="scroll-page">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item title="之前" name="BEFORE"  v-if="beforeList.length>0">
            <div v-for="item in beforeList"  class="item">
              <div class="item_content">
                <img class="isDone_icon" :src="item.isDone===0?uncheckedIcon:checkedIcon" @click="toggleIsDone(item)"/>
                <el-input v-model="item.title" placeholder="请输入事项标题" @focus="setCurrentPlan(item)"  @blur="saveInput(item)"></el-input>
                <el-button class="btn_date" type="text" @click="setCurrentPlan(item)" v-if="item.repeatFlag!=null">{{item.repeatFlag | formatRepeatFlagToShow}}</el-button>
                <el-button class="btn_date" type="text" @click="setCurrentPlan(item)" >{{item.startDate | formatStartDateToShow}}</el-button>
              </div>
              <div class="divider"/>
            </div>
          </el-collapse-item>

          <el-collapse-item title="今天" name="TODAY"  v-if="todayList.length>0" >
            <div v-for="item in todayList"  class="item">
              <div class="item_content">
                <img class="isDone_icon" :src="item.isDone===0?uncheckedIcon:checkedIcon"  @click="toggleIsDone(item)"/>
                <el-input v-model="item.title" placeholder="请输入事项标题"  @focus="setCurrentPlan(item)" @blur="saveInput(item)"></el-input>
                <el-button class="btn_date" type="text" @click="setCurrentPlan(item)"  v-if="item.repeatFlag!=null">{{item.repeatFlag | formatRepeatFlagToShow}}</el-button>
                <el-button class="btn_date" type="text" @click="setCurrentPlan(item)" >{{item.startDate | formatStartDateToShow}}</el-button>
              </div>
              <div class="divider"/>
            </div>
          </el-collapse-item>

          <el-collapse-item title="明天" name="TOMORROW"  v-if="tomorrowList.length>0" >
            <div v-for="item in tomorrowList"  class="item">
              <div class="item_content">
                <img class="isDone_icon" :src="item.isDone===0?uncheckedIcon:checkedIcon"  @click="toggleIsDone(item)"/>
                <el-input v-model="item.title" placeholder="请输入事项标题" @focus="setCurrentPlan(item)" @blur="saveInput(item)"></el-input>
                <el-button class="btn_date" type="text" @click="setCurrentPlan(item)"  v-if="item.repeatFlag!=null">{{item.repeatFlag | formatRepeatFlagToShow}}</el-button>
                <el-button class="btn_date" type="text" @click="setCurrentPlan(item)" >{{item.startDate | formatStartDateToShow}}</el-button>
              </div>
              <div class="divider"/>
            </div>
          </el-collapse-item>

          <el-collapse-item title="接下来七天" name="NEXT_SEVEN"  v-if="nextSevenList.length>0" >
            <div v-for="item in nextSevenList"  class="item">
              <div class="item_content">
                <img class="isDone_icon" :src="item.isDone===0?uncheckedIcon:checkedIcon"  @click="toggleIsDone(item)"/>
                <el-input v-model="item.title" placeholder="请输入事项标题" @focus="setCurrentPlan(item)" @blur="saveInput(item)"></el-input>
                <el-button class="btn_date" type="text"  @click="setCurrentPlan(item)"  v-if="item.repeatFlag!=null">{{item.repeatFlag | formatRepeatFlagToShow}}</el-button>
                <el-button class="btn_date" type="text"  @click="setCurrentPlan(item)" >{{item.startDate | formatStartDateToShow}}</el-button>
              </div>
              <div class="divider"/>
            </div>
          </el-collapse-item>

          <el-collapse-item title="将来" name="FUTURE"  v-if="futureList.length>0" >
            <div v-for="item in futureList"  class="item">
              <div class="item_content">
                <img class="isDone_icon" :src="item.isDone===0?uncheckedIcon:checkedIcon"  @click="toggleIsDone(item)"/>
                <el-input v-model="item.title" placeholder="请输入事项标题" @focus="setCurrentPlan(item)" @blur="saveInput(item)"></el-input>
                <el-button class="btn_date" type="text"  @click="setCurrentPlan(item)" v-if="item.repeatFlag!=null">{{item.repeatFlag | formatRepeatFlagToShow}}</el-button>
                <el-button class="btn_date" type="text" @click="setCurrentPlan(item)" >{{item.startDate | formatStartDateToShow}}</el-button>
              </div>
              <div class="divider"/>
            </div>
          </el-collapse-item>

          <el-collapse-item title="待定日期" name="WITHOUT_DATE"  v-if="withoutDateList.length>0" >
            <div v-for="item in withoutDateList"  class="item">
              <div class="item_content">
                <img class="isDone_icon" :src="item.isDone===0?uncheckedIcon:checkedIcon"  @click="toggleIsDone(item)"/>
                <el-input v-model="item.title" placeholder="请输入事项标题" @focus="setCurrentPlan(item)" @blur="saveInput(item)"></el-input>
                <el-button class="btn_date" type="text"  @click="setCurrentPlan(item)"  v-if="item.repeatFlag!=null">{{item.repeatFlag | formatRepeatFlagToShow}}</el-button>
                <el-button class="btn_date" type="text"  @click="setCurrentPlan(item)" >{{item.startDate | formatStartDateToShow}}</el-button>
              </div>
              <div class="divider"/>
            </div>
          </el-collapse-item>

          <el-collapse-item title="已完成" name="IS_DONE"  v-if="isDoneList.length>0" >
            <div v-for="item in isDoneList"  class="item">
              <div class="item_content" style="color: #999999">
                <img class="isDone_icon" :src="item.isDone===0?uncheckedIcon:checkedIcon"  @click="toggleIsDone(item)"/>
                <el-input v-model="item.title" placeholder="请输入事项标题" @focus="setCurrentPlan(item)" @blur="saveInput(item)"></el-input>
                <el-button class="btn_date" type="text"  @click="setCurrentPlan(item)"  v-if="item.repeatFlag!=null">{{item.repeatFlag | formatRepeatFlagToShow}}</el-button>
                <el-button class="btn_date"   style="color: #999999" type="text"  @click="setCurrentPlan(item)" >{{item.startDate | formatStartDateToShow}}</el-button>
              </div>
              <div class="divider"/>
            </div>
          </el-collapse-item>

        </el-collapse>

      </el-scrollbar>
    </div>

    <div id="right">
      <div  id="rightContainer" v-if="currentPlan!==null&&currentPlan!==''">
        <div id="rightTop" >
          <el-date-picker
            v-model="currentPlan.startDate"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            @change="changeDate()"
            style="width:10rem" />
        </div>
        <el-input class="rightTitle"  v-model="currentPlan.title" @click.right="rightClick" @blur="saveInput(currentPlan)"   @input="saveInput(currentPlan,false)"/>
        <div  class="rightContent" >
          <el-input class="rightInput"  v-model="currentPlan.content"  @blur="saveInput(currentPlan)"  @input="saveInput(currentPlan,false)"
                    type="textarea" />
        </div>
        <div id="rightBottom" >
          <el-popover
            placement="top"
            width="160"
            v-model="collectionPopover">
            <div  id="collection_content">
              <div class="collection_item" @click="changeCollection(null)">
                <img class="collection_item_icon" src="../images/ic_drawer_list.png" />
                <div>待办箱</div>
              </div>
              <div class="collection_item" v-for="item in collectionList" @click="changeCollection(item)">
                <img class="collection_item_icon" src="../images/ic_select_collection.png"/>
                <div>{{item.collectionName}}</div>
              </div>
            </div>
            <div slot="reference"  id="collection_ref">
              <img id="collection_ref_icon" src="../images/ic_list_gray_b9b9b9.png"/>
              <div>{{getCollectionNameOfCurrentPlan()}}</div>
            </div>
          </el-popover>
          <div style="flex-grow: 1"></div>
          <el-button  icon="el-icon-delete" circle size="small" @click="openDeleteConfirmDialog()"></el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import { getPlanAllWithUpdateTime, updateByPublishUser, addPlan } from '@/api/plan'
import { getConfigJSON } from '@/api/config'
import { EventBus } from '@/config/eventBus';
import storage from '@/store/storage';
import user from '@/store/modules/user'
import planRepeatUtil from '@/utils/planRepeatUtil'

export default {
  created () {
    this.user = storage.get('user')
    console.log(this.user)
    if (this.user == null) {
      this.$message.error('请重新登录')
      this.$router.push('/login')
      return
    }
    //  对user vipTime的判断
    if (this.user.vipTime == null || this.user.vipTime == '') {
      storage.remove('user')
      this.$message.error('请重新登录,会员未开通')
      this.$router.push('/login')
      return
    }
    if (this.user.vipTime < new Date().getTime()) {
      storage.remove('user')
      this.$message.error('请重新登录,会员已过期')
      this.$router.push('/login')
      return
    }

    this.netPlanAllWithUpdateTime()
    this.dbGetCollectionList()
  },
  data () {
    return {
      planList: [],
      planCollectionList: [],

      activeNames: ['BEFORE', 'TODAY', 'TOMORROW', 'NEXT_SEVEN', 'FUTURE', 'WITHOUT_DATE'],
      beforeList: [],
      todayList: [],
      tomorrowList: [],
      nextSevenList: [],
      futureList: [],
      withoutDateList: [],
      isDoneList: [],
      currentPlan: '',
      collectionList: [],
      collectionPopover: false,

      newPlanTitle: '',

      user: '',

      uncheckedIcon: require('@/images/ic_todo_unchecked_gray757575.png'),
      checkedIcon: require('@/images/ic_todo_checked_gray.png'),
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '明天',
            onClick (picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '后天',
            onClick (picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 2);
              picker.$emit('pick', date);
            }
          }]
      }
    }
  },
  computed: {
    currentType () {
      return this.$store.getters.currentType
    },
    currentCollection () {
      return this.$store.getters.currentCollection
    }
  },
  watch: {
    currentType: {
      handler (newVal, oldVal) {
        console.log('currentType changed');
        this.processDataByTime() // TODO 避免和下面的currentCollection重复执行
      },
      deep: true
    },
    currentCollection: {
      handler (newVal, oldVal) {
        console.log('currentCollection changed');
        this.processDataByTime()
      },
      deep: true
    }
  },
  methods: {
    rightClick (e) {
      console.log(e.pageX)
      console.log(e.pageY)
    },
    async netPlanAllWithUpdateTime () {
      let updateTimePlan = 0
      let updateTimeCollection = 0

      await this.db.plan.reverse().sortBy('updateTime').then((query) => {
        if (query.length > 0) {
          updateTimePlan = query[0].updateTime;
        }
      })
      await this.db.planCollection.reverse().sortBy('updateTime').then((query) => {
        query.forEach(item => {
          console.log(item.updateTime)
        })
        console.log(query)
        if (query.length > 0) {
          updateTimeCollection = query[0].updateTime;
        }
      })

      console.log(updateTimePlan)
      console.log(updateTimeCollection)

      let params = {
        userID: this.user.userID,
        updateTimePlan: updateTimePlan,
        updateTimeCollection: updateTimeCollection
      }
      getPlanAllWithUpdateTime(params).then(res => {
        const data = res.data
        this.saveDataFromNetServer(data.planCollectionList, data.planList)
      })
    },
    async saveDataFromNetServer (planCollectionList, planList) {
      // 先处理collection
      await planCollectionList.forEach(item => {
        // console.log(item)
        item.needUpdate = 0
        this.db.planCollection.get({ id: item.id }).then((queryCollection) => {
          console.log(queryCollection);
          if (queryCollection == null) {
            this.db.planCollection.add(item).then(result => {
              console.log('result--' + result)
            }).catch(error => {
              console.log('error--' + error)
            })
          } else {
            if (queryCollection.needUpdate === 1 && queryCollection.updateTime === item.updateTime) {
              // 本地已修改、且本地updateTime=服务器updateTime，说明本地已修改的还没push给服务器
              console.log('本地已修改 queryCollection=' + queryCollection)
            } else {
              console.log('更新 collection=' + item);
              item.localID = queryCollection.localID
              this.db.planCollection.put(item).then(result => {
                console.log('result--' + result)
              }).catch(error => {
                console.log('error--' + error)
              })
            }
          }
        })
        // console.log('saveDataFromNetServer--planCollection')
      })

      // 再处理plan
      await planList.forEach(item => {
        item.needUpdate = 0
        this.db.plan.get({ id: item.id }).then((queryPlan) => {
          console.log(item)
          // console.log(queryPlan)
          if (queryPlan == null) {
            this.db.plan.add(item).then(result => {
              console.log('result--' + result)
            }).catch(error => {
              console.log('error--' + error)
            })
            // TODO 插入本地日历提醒
          } else {
            if (queryPlan.needUpdate === 1 && queryPlan.updateTime === item.updateTime) {
              // 本地已修改、且本地updateTime=服务器updateTime，说明本地已修改的还没push给服务器
              console.log('本地已修改 queryPlan=' + queryPlan)
            } else {
              item.localID = queryPlan.localID
              this.db.plan.put(item).then(result => {
                console.log('result--' + result)
                // This code is called if resolve() was called in the Promise constructor
              }).catch(error => {
                console.log('error--' + error)
                // This code is called if reject() was called in the Promise constructor, or
                // if an exception was thrown in either constructor or previous then() call.
              })
              // TODO 插入本地日历提醒
            }
          }
        })
        // console.log('saveDataFromNetServer--plan')
      })
      console.log('refreshUI')
      setTimeout(this.refreshUI, 600)// 需要做个延时，插入数据库需要时间
    },
    async refreshUI () {
      await this.processDataByTime()
      EventBus.$emit('sideRefresh', '');

      await this.dbGetCollectionList()
      await this.pushDataToServer()
    },
    async processDataByTime () {
      let allUnDoneList = []
      await this.db.plan.where({ isDone: 0, isDeleted: 0 }).sortBy('startDate').then((queryPlan) => {
        // console.log('queryPlan')
        allUnDoneList = queryPlan
      })
      // console.log(allUnDoneList)

      let top10IsDoneList = []
      await this.db.plan.where({ isDone: 1, isDeleted: 0 }).reverse().sortBy('doneTime').then((queryPlan) => {
        // console.log('top10IsDoneList')
        top10IsDoneList = queryPlan // TODO 暂时全部都显示
      })
      // console.log(top10IsDoneList)

      let unDoneList = []
      let isDoneList = []

      switch (this.currentType) {
        case 'ALL':
          // 就是上面数据库查出来的
          unDoneList = allUnDoneList
          isDoneList = top10IsDoneList
          break;
        case 'TODAY':
          allUnDoneList.forEach(item => {
            if (item.startDate != null && item.startDate !== '') {
              let today = dayjs().format('YYYY-MM-DD')
              let diff = dayjs(item.startDate).diff(dayjs(today), 'day')
              if (diff === 0) {
                unDoneList.push(item)
              }
            }
          }
          )

          top10IsDoneList.forEach(item => {
            let isAdd = false;
            if (item.startDate != null && item.startDate !== '') {
              let today = dayjs().format('YYYY-MM-DD')
              let diff = dayjs(item.startDate).diff(dayjs(today), 'day')
              if (diff === 0) {
                isDoneList.push(item)
                isAdd = true // 避免下面也添加一次
              }
            }
            if (item.doneDate != null && item.doneDate !== '') {
              let diff = dayjs().diff(dayjs(item.doneDate), 'day')
              if (diff === 0) {
                if (!isAdd) {
                  isDoneList.push(item);
                }
              }
            }
          }
          )
          break;
        case 'UN_CATALOGED':
          allUnDoneList.forEach(item => {
            if (item.collectionID == null || item.collectionID === '') {
              unDoneList.push(item)
            }
          })

          top10IsDoneList.forEach(item => {
            if (item.collectionID == null || item.collectionID === '') {
              isDoneList.push(item)
            }
          })
          break;
        case 'COLLECTION':
          allUnDoneList.forEach(item => {
            if (item.collectionID != null && item.collectionID !== '') {
              if (item.collectionID === this.currentCollection.id) {
                unDoneList.push(item)
              }
            }
          })

          top10IsDoneList.forEach(item => {
            if (item.collectionID != null && item.collectionID !== '') {
              if (item.collectionID === this.currentCollection.id) {
                isDoneList.push(item)
              }
            }
          })
          break;
      }
      this.sortPlanByTime(unDoneList, isDoneList)
    },
    sortPlanByTime (unDonePlanList, isDonePlanList) {
      let planListWithoutDate = []
      let planListHasDate = []
      unDonePlanList.forEach(item => {
        if (item.startDate != null && item.startDate !== '') {
          planListHasDate.push(item)
        } else {
          planListWithoutDate.push(item)
        }
      })

      // console.log(planListHasDate)
      // console.log(planListWithoutDate)
      this.beforeList = []
      this.todayList = []
      this.tomorrowList = []
      this.nextSevenList = []
      this.futureList = []
      this.withoutDateList = []
      this.isDoneList = []

      planListHasDate.forEach(item => {
        let today = dayjs().format('YYYY-MM-DD')
        let diff = dayjs(item.startDate).diff(dayjs(today), 'day')
        // console.log(diff)
        if (diff < 0) {
          this.beforeList.push(item)
        } else if (diff === 0) {
          this.todayList.push(item)
        } else if (diff === 1) {
          this.tomorrowList.push(item)
        } else if (diff <= 7) {
          this.nextSevenList.push(item)
        } else {
          this.futureList.push(item)
        }
      })
      // 没有设置日期的，按创建时间最晚在前排序
      planListWithoutDate.sort(function (a, b) {
        return a.createTime - b.createTime
      });
      this.withoutDateList = planListWithoutDate

      // Today今天和tomorrow明天 根据sortInDate进行排序
      this.todayList.sort(function (a, b) {
        return a.sortInDate - b.sortInDate
      });
      this.tomorrowList.sort(function (a, b) {
        return a.sortInDate - b.sortInDate
      });

      // 已完成，按完成时间最晚在前排序
      isDonePlanList.sort(function (a, b) {
        return a.doneTime - b.doneTime
      });
      this.isDoneList = isDonePlanList
    },
    netGetConfigJSON () {
      let params = {
        userID: 'x2rgm',
        updateTimePlan: 0,
        updateTimeCollection: 0
      }
      let data = {
        versionCode: 1,
        versionName: '121'
      }
      getConfigJSON(data).then(res => {
        const data = res.data
      })
    },
    handleChange (val) {},
    setCurrentPlan (plan) {
      console.log(plan)
      this.currentPlan = plan
    },
    async saveInput (plan , needPush = true) {
      console.log('saveInput   ' + JSON.stringify(plan))
      console.log('needPush = ' + needPush)

      await this.db.plan.get({ localID: plan.localID }).then((queryPlan) => {
        let hasChanged = false
        if (plan.title.trim() !== queryPlan.title.trim()) {
          hasChanged = true
        }
        if (plan.content !== queryPlan.content) {
          hasChanged = true
        }

        if (hasChanged) {
          console.log('plan  change')
          plan.needUpdate = 1

          if (queryPlan.id != null) {
            plan.id=queryPlan.id
            //避免因为页面没刷新，产生两条重复数据
            //之前出现bug的场景：新建一个plan，立刻去编辑这个plan，就会有2条数据。
            // 虽然调用了pushDataToServer，但push结束后并没有刷新页面，这个时候页面的plan还是没有id
          }

          this.db.plan.put(plan, plan.localID)


          if (needPush) {
            this.pushDataToServer()
          }

        } else {
          console.log('plan no change')
        }
      })
    },
    getLeftHeader () {
      if (this.currentType == 'ALL') {
        return '全部'
      } else if (this.currentType == 'TODAY') {
        return '今天'
      } else if (this.currentType == 'UN_CATALOGED') {
        return '待办箱'
      } else if (this.currentType == 'COLLECTION') {
        return this.currentCollection.collectionName
      }
    },
    async dbGetCollectionList () {
      this.db.planCollection.toArray().then(queryList => {
        this.collectionList = queryList
      }
      )
    },
    getCollectionNameOfCurrentPlan () {
      let collectionName = '待办箱'
      if (this.currentPlan.collectionID == null) {
        return collectionName
      } else {
        this.collectionList.forEach(item => {
          if (this.currentPlan.collectionID === item.id) {
            collectionName = item.collectionName
          }
        })
        return collectionName
      }
    },
    dbAddPlan () {
      if (this.user == null || this.user == '') {
        this.$message.error('user==null 请先登录账号')
        return
      }

      let title = this.newPlanTitle.trim()
      if (title.length === 0) {
        return
      }

      this.newPlanTitle = ''

      let plan = {
        id: null,
        userID: this.user.userID,
        title: title,
        needUpdate: 1,
        isDone: 0,
        isDeleted: 0,
        content: '',
        imgList: '',
        startDate: '',
        startTime: null,
        endTime: null,
        lockMinute: null,
        doneTime: null,
        doneDate: null,
        sortInDate: null,
        createTime: null,
        updateTime: null,
        isPrimary: null,
        isBlock: null,
        isHideByUs: null,
        reportNum: null,
        auditStatus: null,
        collectionID: null,
        parentID: null,
        childCount: null,
        remindList: null
      }
      this.db.plan.put(plan)

      this.processDataByTime()
      this.pushDataToServer()
      EventBus.$emit('sideRefresh', '');
    },
    async pushDataToServer () {
      if (this.user == null) {
        this.$message.error('user==null 用户未登录 pushDataToServer')
        return
      }
      console.log('list')
      // 先向服务器拉取数据，再将本地需要update到服务器的数据push给服务器
      let queryList = []
      queryList = await this.db.plan.where({ needUpdate: 1 }).toArray()
      console.log(queryList)
      queryList.forEach(item => {
        if (item.id == null) {
          console.log('netAddPlan')
          // getId()==null说明本地新增的，还没同步给服务器
          this.netAddPlan(item)
        } else {
          this.netUpdatePlanForEdit(item)
        }
      })
    },
    async netAddPlan (item) {
      let query = await this.db.plan.where({ localID: item.localID }).first()
      console.log('query=' + JSON.stringify(query))
      if (query != null && query.id != null) {
        // 再次去数据库查询下该plan是否已获得planID,有的话，说明已推送给服务器，改成update接口
        await this.netUpdatePlanForEdit(item)
        return
      }

      addPlan(item).then(res => {
        const data = res.data
        console.log("addPlan Resp = "+JSON.stringify(data))
        data.needUpdate = 0
        this.db.plan.put(data, item.localID)
      })
    },
    async netUpdatePlanForEdit (item) {
      updateByPublishUser(item).then(res => {
        const data = res.data
        // console.log(data)
        data.needUpdate = 0
        this.db.plan.put(data, item.localID)
      })
    },
    toggleIsDone (item) {
      if (!planRepeatUtil.isRepeatPlan(item)) {
        // 正常逻辑 非重复任务
        if (item.isDone == 0) {
          item.isDone = 1
        } else {
          item.isDone = 0
        }
        this.dbSavePlan(item)
      } else {
        // 重复任务
        this.dbSavePlanForRepeat(item)
      }
    },
    changeDate () {
      this.dbSavePlan(this.currentPlan)
    },
    changeCollection (collection) {
      if (collection == null) {
        this.currentPlan.collectionID = null
      } else {
        this.currentPlan.collectionID = collection.id
      }
      this.collectionPopover = false
      this.dbSavePlan(this.currentPlan)
    },
    dbSavePlan (item) {
      // console.log(item)
      item.needUpdate = 1
      this.db.plan.put(item, item.localID)
      setTimeout(this.processDataByTime, 160)// 做个延时，避免icon切换是否已完成图标导致闪动
      this.pushDataToServer()
      EventBus.$emit('sideRefresh', '');
    },
    dbSavePlanForRepeat (item) {
      let donePlan = JSON.parse(JSON.stringify(item))// 相当于java里面的clone

      item.isDone = 0
      item.startDate = planRepeatUtil.getNextStartDate(item)
      item.needUpdate = 1
      this.db.plan.put(item, item.localID)

      delete donePlan.localID
      donePlan.id = null
      donePlan.repeatFlag = null
      donePlan.createTime = null
      donePlan.updateTime = null
      donePlan.repeatPlanID = item.id

      donePlan.isDone = 1
      donePlan.needUpdate = 1

      console.log(donePlan)
      this.db.plan.add(donePlan).then(result => {
        console.log('result--' + result)
        // This code is called if resolve() was called in the Promise constructor
      }).catch(error => {
        console.log('error--' + error)
        // This code is called if reject() was called in the Promise constructor, or
        // if an exception was thrown in either constructor or previous then() call.
      })

      setTimeout(this.processDataByTime, 160)// 做个延时，避免icon切换是否已完成图标导致闪动
      this.pushDataToServer()
      EventBus.$emit('sideRefresh', '');
    },
    openDeleteConfirmDialog () {
      let message = '此操作将删除任务：' + this.currentPlan.title
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deletePlan(this.currentPlan)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
      });
    },
    deletePlan (item) {
      item.isDeleted = 1
      this.dbSavePlan(item)
    },
    testNextStartDate (item) {
      let nextStartDate = planRepeatUtil.getNextStartDate(item)
      console.log('testNextStartDate==item==' + item.title + '  ' + item.startDate)
      console.log('testNextStartDate==' + nextStartDate)
    }
  },
  filters: {
    formatStartDateToShow (startDate) {
      if (startDate == null || startDate === '') {
        return ''
      }

      if (!dayjs().isSame(startDate, 'year')) {
        return dayjs(startDate).format('YYYY年M月D号');
      } else {
        let today = dayjs().format('YYYY-MM-DD');
        let diff = dayjs(startDate).diff(dayjs(today), 'day');
        if (diff === 0) {
          return '今天';
        }
        if (diff === 1) {
          return '明天';
        }

        let str = dayjs(startDate).format('M月D号')
        return str
        // return str.replaceAll('0', '')
      }
    },
    formatRepeatFlagToShow (flag) {
      console.log(flag)
      if (flag == null || flag === '') {
        return ''
      }
      let showFormatFlag = planRepeatUtil.getShowFormatFlag(flag)
      return showFormatFlag + '重复'
    }
  }
}
</script>

<style lang="scss" scoped>
#container{
  display: flex;
  width: 100%;
  height: 100%;
  background: white;
}
#left{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
}
#list{
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.scroll-page {
  overflow: hidden;
  /deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

#leftHeader{
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.icon{
  width: 1.35rem;
  margin-right: 1rem;
}

.item{
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.item_content{
  display: flex;
  align-items: center;
  padding-right: 0.25rem;
}
.divider{
  background: #eeeeee;
  height: 1px;
  width: 100%;
  visibility: hidden;
}

.isDone_icon{
  width: 1.25rem;
  height: 1.25rem;
}
.isDone_icon:hover{
  cursor: pointer;
}

.more_icon{
  width: 1.25rem;
  margin-top: 3px;
  margin-left: 1rem;
}

/deep/ .el-scrollbar__wrap{
  background: white;
  padding-right: 10px;
}

/deep/ .el-input__inner {
  border: none;
}
/deep/ .el-textarea__inner {
  border: none;
}
/deep/ .el-collapse-item__content {
  padding-bottom: 6px;
}
/deep/ .el-collapse-item__header {
  //background: #e7ecfd;
  height: 3rem;
}
.btn_date{
  color: #617fde;
}

#right{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#rightContainer{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.rightTitle{
  margin-top: 0.5rem;
}
.rightTitle /deep/ .el-input__inner{
  color: #333333;
  font-size: 1.5rem;
}

.rightContent{
  width: 100%;
  flex-grow: 1;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.rightInput{
 height: 100%;
}

.rightContent /deep/ .el-textarea__inner{
  //color: #333333;
  font-size: 0.95rem;
  resize:none;
  width: 100%;
  height: 100%;
}
.rightContent /deep/ .el-textarea__inner::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.rightContent /deep/ .el-textarea__inner::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #dfdfdf;
}
.rightContent /deep/ .el-textarea__inner::-webkit-scrollbar-track {
  background-color: transparent;
}

#rightTop{
  width: 100%;
  height: 2.25rem;
  background: #fff;
  display: flex;
  align-items: center;
}
#rightBottom{
  width: 100%;
  height: 2.25rem;
  background: #fff;
  display: flex;
  align-items: center;
}

#collection_ref{
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  color: #666666;
}
#collection_ref:hover{
  cursor: pointer;
}
#collection_ref_icon{
  width: 0.9rem;
  margin-left: 1rem;
  margin-right: 0.2rem;
}
.collection_item_icon{
  width: 1rem;
  margin-right: 0.2rem;
}
.collection_item{
  display: flex;
  align-items: center;
  padding: 0.3rem;
  margin-left: 0.3rem;
  background: #ffffff;
}
.collection_item:hover{
  background: #eeeeee;
  cursor: pointer;
}

</style>
