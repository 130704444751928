import constant from '../config/constant'
import dayjs from 'dayjs'
import format from 'element-ui/src/locale/format'

export default {
  getFreq: function (flag) {
    let rules = flag.split(';')
    // console.log(rules)
    let freq = ''
    rules.forEach(str => {
      if (str.indexOf(constant.FREQ) > -1) {
        freq = str.replace(constant.FREQ + '=', '')
        // console.log(freq)
      }
    })
    return freq
  },
  getByMonthDay: function (flag) {
    let rules = flag.split(';')
    let monthDay = ''
    rules.forEach(str => {
      if (str.indexOf(constant.BYMONTHDAY) > -1) {
        monthDay = str.replace(constant.BYMONTHDAY + '=', '')
      }
    })
    console.log('monthDay==' + monthDay)
    return monthDay
  },
  getByDay: function (flag) {
    let rules = flag.split(';')
    let byDay = ''
    rules.forEach(str => {
      if (str.indexOf(constant.BYDAY) > -1) {
        byDay = str.replace(constant.BYDAY + '=', '')
        // console.log(byDay)
      }
    })
    return byDay
  },
  transWeekDayToSaveFormat (str) {
    switch (str) {
      case '一':
        return 'MO';
      case '二':
        return 'TU';
      case '三':
        return 'WE';
      case '四':
        return 'TH';
      case '五':
        return 'FR';
      case '六':
        return 'SA';
      case '日':
        return 'SU';
    }
    return null;
  },
  transWeekDayToShowFormat (str) {
    switch (str) {
      case 'MO':
        return '一';
      case 'TU':
        return '二';
      case 'WE':
        return '三';
      case 'TH':
        return '四';
      case 'FR':
        return '五';
      case 'SA':
        return '六';
      case 'SU':
        return '日';
    }
    return null;
  },
  getShowFormatByDay: function (flag) {
    let byDay = this.getByDay(flag)
    let sb = ''
    let byDayArray = byDay.split(',')
    byDayArray.forEach(str => {
      sb = sb + this.transWeekDayToShowFormat(str)
    })
    return sb
  },
  getShowFormatByMonthDay: function (flag) {
    let byMonthDay = this.getByMonthDay(flag)
    let sb = ''
    let byDayArray = byMonthDay.split(',')
    byDayArray.forEach(str => {
      sb = sb + str + ' '
    })
    if (sb.length > 1) {
      sb = sb.substr(0, sb.length - 1)// 删除最后一位空格字符
    }
    return sb
  },
  getShowFormatFlag: function (flag) {
    let sb = ''
    let freq = this.getFreq(flag)
    switch (freq) {
      case constant.DAY:
        sb = '每天'
        break;
      case constant.WEEK:
        sb = '每周'
        sb = sb + this.getShowFormatByDay(flag)
        break;
      case constant.MONTH:
        sb = '每月'
        sb = sb + this.getShowFormatByMonthDay(flag)
        break;
    }
    return sb
  },
  getNextStartDate: function (plan) {
    // console.log('getNextStartDate')
    // console.log(plan)
    let startDate = '123'

    let flag = plan.repeatFlag
    let freq = this.getFreq(flag)
    switch (freq) {
      case constant.DAY:
        startDate = this.getNextStartDateWhenFreqDay(plan)
        break;
      case constant.WEEK:
        startDate = this.getNextStartDateWhenFreqWeek(plan)
        break;
      case constant.MONTH:
        startDate = this.getNextStartDateWhenFreqMonth(plan);
        break;
    }
    return startDate
  },
  getNextStartDateWhenFreqDay: function (plan) {
    let cal = dayjs(plan.startDate)
    cal = cal.add(1, 'day')
    return this.format(cal)
  },
  getNextStartDateWhenFreqWeek: function (plan) {
    let byDay = this.getByDay(plan.repeatFlag)
    let byDayArray = byDay.split(',')

    if (byDayArray.length == 0) {
      return ''
    }
    let cal = this.getCal(plan.startDate)
    console.log('cal--' + cal.format('YYYY-MM-DD'))
    let dayOfWeek = cal.format('d')// 0-Sunday  6-Saturday
    if (dayOfWeek == 0) {
      dayOfWeek = 7
    }
    console.log('dayOfWeek--' + dayOfWeek)
    // 本周的对比

    for (let i = 0; i < byDayArray.length; i++) {
      let oneDay = byDayArray[i];
      let index = this.getDayOfWeek(oneDay)
      console.log('index--' + index)
      if (index > dayOfWeek) {
        return this.getThisWeek_DayOfWeek(plan.startDate, index)
      }
    }

    // 下周的第一个
    let nextWeekFirstIndex = this.getDayOfWeek(byDayArray[0])
    return this.getNextWeek_DayOfWeek(plan.startDate, nextWeekFirstIndex)
  },
  getDayOfWeek: function (oneDay) {
    switch (oneDay) {
      case 'MO':
        return 1;
      case 'TU':
        return 2;
      case 'WE':
        return 3;
      case 'TH':
        return 4;
      case 'FR':
        return 5;
      case 'SA':
        return 6;
      case 'SU':
        return 7;
    }
    return -1;
  },
  getThisWeek_DayOfWeek: function (startDate, dayOfWeek) {
    if (dayOfWeek == 7) {
      dayOfWeek = 0;// 周日在 dayJs里面常量是0
    }
    let cal = this.getCal(startDate)
    cal = cal.day(dayOfWeek)
    if (dayOfWeek == 0) {
      // dayOfWeek==0 此时cal.day(0)是上周日，所以为了拿到本周日，这里要加上7天
      cal = cal.add(7, 'day')
    }
    return this.format(cal)
  },
  getNextWeek_DayOfWeek: function (startDate, dayOfWeek) {
    if (dayOfWeek == 7) {
      dayOfWeek = 0;// 周日在 dayJs里面常量是0
    }
    let cal = this.getCal(startDate)
    // console.log('dayOfWeek=' + dayOfWeek)
    if (cal.day() == 0) { // 判断startDate是否为周日
      cal = cal.add(1, 'day')// 切换到下周一
    } else {
      cal = cal.day(1)// 切换到本周一
      // console.log('切换到本周一=' + this.format(cal))
      cal = cal.add(7, 'day')// 切换到下周一
    }
    // console.log('切换到下周一=' + this.format(cal))
    cal = cal.day(dayOfWeek)// 设定 下周的具体星期几
    // console.log('下周的具体星期几=' + this.format(cal))
    return this.format(cal)
  },
  getNextStartDateWhenFreqMonth: function (plan) {
    let monthDay = this.getByMonthDay(plan.repeatFlag)
    let monthDayArray = monthDay.split(',')
    if (monthDayArray.length == 0) {
      return ''
    }
    let cal = this.getCal(plan.startDate)
    let dayOfMonth = cal.date()
    // 本月的对比
    for (let i = 0; i < monthDayArray.length; i++) {
      let oneDay = monthDayArray[i]
      let index = parseInt(oneDay)
      if (index > dayOfMonth) {
        return this.getThisMonth_DayOfMonth(plan.startDate, index)
      }
    }
    // 下周的第一个
    let nextWeekFirstIndex = parseInt(monthDayArray[0]);
    return this.getNextMonth_DayOfMonth(plan.startDate, nextWeekFirstIndex);
  },
  getThisMonth_DayOfMonth: function (startDate, index) {
    let cal = this.getCal(startDate)
    cal = cal.date(index)
    return this.format(cal)
  },
  getNextMonth_DayOfMonth: function (startDate, index) {
    let cal = this.getCal(startDate)
    cal = cal.date(1)// 切换回本月1号
    cal = cal.add(1, 'month') // 增加一个月
    cal = cal.date(index)
    return this.format(cal)
  },
  getCal: function (startDate) {
    return dayjs(startDate)
  },
  format: function (cal) {
    return cal.format('YYYY-MM-DD')
  },
  isRepeatPlan: function (plan) {
    if (plan.repeatFlag != null && (plan.repeatFlag !== '' && plan.startDate != null && plan.startDate !== '')) {
      return true
    }
    return false
  }
  // repeatFlagatPlanBeSetDone: function (cal) {
  //   return cal.format('YYYY-MM-DD')
  // }

}
