import request from '@/utils/request'

export function getPlanAllWithUpdateTime (params) {
  return request({
    url: 'common/api/plan/getPlanAllWithUpdateTime',
    method: 'post',
    data: {
      data: params
    }
  })
}
export function addPlan (params) {
  return request({
    url: 'common/api/plan/addPlan',
    method: 'post',
    data: {
      data: params
    }
  })
}
export function updateByPublishUser (params) {
  return request({
    url: 'common/api/plan/updateByPublishUser',
    method: 'post',
    data: {
      data: params
    }
  })
}
