export const ALL = 'ALL'

export const FREQ = 'FREQ'
export const BYDAY = 'BYDAY'
export const BYMONTHDAY = 'BYMONTHDAY'

export const NO_REPEAT = 'NO_REPEAT'
export const DAY = 'DAY'
export const WEEK = 'WEEK'
export const MONTH = 'MONTH'

export default { ALL, FREQ, BYDAY, BYMONTHDAY, NO_REPEAT, DAY, WEEK, MONTH }
